import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import logo from "../img/logo.png";
let Recaptcha = require("react-recaptcha");

const Home = () => {
  const [pass, setPass] = useState("");
  const [passErr, setPassErr] = useState("");
  const [captchaErr, setCaptchaerr] = useState("");
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const onClickLogin = (event) => {
    event.preventDefault();
    if (!isValid && !captchaErr) {
      setIsValid(true);
      setCaptchaerr("Please verify the captcha");
    } else {
      setIsValid(false);
      setCaptchaerr("");
    }
    if (pass === "CAOneTechCloud" && captchaErr.length === 0) {
      navigate("main");
    }
    if (pass !== "CAOneTechCloud") {
      setPassErr("Wrong Password. Please try again");
    }
  };

  let verifyCallback = function (response) {
    if (response) {
      setCaptchaerr("");
    }
  };

  return (
    <div>
      
        <div>
          <header className="uk-background-primary uk-background-norepeat uk-background-cover uk-background-center-center uk-light">
            <nav className="uk-navbar-container">
              <div className="uk-container">
                <div data-uk-navbar style={{ display: "flex" }}>
                  <div className="uk-navbar-left uk-text-left">
                    <a
                      className="uk-navbar-item uk-logo uk-visible@m"
                      href="#"
                    >
                      <img src={logo} alt="CA-One" />
                    </a>
                    <a
                      className="uk-navbar-toggle uk-hidden@m"
                      href="#offcanvas-docs"
                      data-uk-toggle
                    >
                      <span data-uk-navbar-toggle-icon></span>{" "}
                      <span className="uk-margin-small-left">Articles</span>
                    </a>
                  </div>
                  <div className="uk-navbar-center uk-hidden@m">
                    <a className="uk-navbar-item uk-logo" href="#">
                      <img src={logo} alt="CA-One" />
                    </a>
                  </div>
                  <div className="uk-navbar-right">
                    <ul className="uk-navbar-nav uk-visible@m">
                      <div className="uk-navbar-item">
                        <a
                          className="uk-button uk-button-primary-outline"
                          href="#"
                        >
                          Back to Website
                        </a>
                      </div>
                    </ul>
                    <a
                      className="uk-navbar-toggle uk-hidden@m"
                      href="#offcanvas"
                      data-uk-toggle
                    >
                      <span data-uk-navbar-toggle-icon></span>{" "}
                      <span className="uk-margin-small-left">Menu</span>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
            <div
              className="uk-section uk-position-relative uk-position-z-index"
              data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
            >
              <div className="uk-container">
                <h1 className="uk-text-center uk-margin-remove-top">
                  CA-One Portal
                </h1>
              </div>
            </div>
          </header>
          <div className="uk-section uk-section-muted">
            <div className="uk-container">
              <h2 className="uk-text-left uk-margin-remove-top uk-margin-medium-bottom mb-2">
                Login
              </h2>
              <div
                className="uk-child-width-1-2@m uk-grid-match- uk-grid-small"
                data-uk-grid
              >
                <form>
                  <div className="uk-margin">
                    <div className="uk-inline">
                      <div>
                        <div className=" uk-inline uk-border-rounded">
                          <div className="uk-flex-middle" data-uk-grid>
                            <div
                              style={{ display: "flex", marginBottom: "1rem" }}
                            >
                              <div
                                className="uk-flex-middle"
                                style={{ marginRight: "1rem" }}
                              >
                                <input
                                  className="uk-input"
                                  value={pass}
                                  onChange={(e) => setPass(e.target.value)}
                                  type="password"
                                  id="password"
                                />
                              </div>
                              <div className="uk-flex-middle">
                                <div
                                  className="uk-flex-middle"
                                  onClick={onClickLogin}
                                >
                                  <input
                                    type="submit"
                                    className="uk-button uk-button-primary"
                                    value="Login"
                                    id="login"
                                  />
                                </div>
                              </div>
                            </div>
                            <div id="messeges" style={{ color: "red" }}>
                              {passErr}
                            </div>
                            <div style={{marginBottom: '10px'}}>
                              <small><i>If you need the password, please request from your manager.</i></small>
                            </div>
                            <div>
                              {/* <div className='g-recaptcha' id='captcha' data-sitekey='6LelCSkgAAAAAIbzly-aV7p7SxpkNEuHEfK2kKrE'></div> */}
                              <Recaptcha
                                sitekey="6Ldll0AgAAAAAD2qxVSzVpoFQEeyzjNgZ2hcoj0F"
                                render="explicit"
                                verifyCallback={verifyCallback}
                              />
                              <div id="cap-msg" style={{ color: "red" }}>
                                {captchaErr}
                              </div>
                            </div>

                            
                          </div>
                        </div>
                        <div>
                          <div className=" uk-inline uk-border-rounded"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default Home;
