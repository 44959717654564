import React from 'react';
import '../App.css';
import ind from '../img/india.png';
import us from '../img/united-states.png';
import eezy from '../img/eezypost.png';
import eezyHire2 from '../img/eezyhire-2.png';
import nurtureClient from '../img/Nurture-CLient.png';
import nurtureClinic from '../img/Nurture Clinic.png';
import ca from '../img/CA-One.png';
import logo from '../img/logo.png';
import react from '../img/react.png';
import emergency from '../img/emergency.png'
import handbook from '../img/handbook.png';
import firstaid from '../img/firstaid.png';
import hrpolocies from '../img/hrpolocies.png';
import pregnant from '../img/pregnant.png';
import slavery from '../img/slavery.png'
import harrassment from '../img/harrassment.png'
import paternity from '../img/paternity.png'


const Main = () => {
  return (
    <div>
      <header className='uk-background-primary uk-background-norepeat uk-background-cover uk-background-center-center uk-light uk-header'>
        <nav className='uk-navbar-container'>
          <div className='uk-container'>
            <div data-uk-navbar style={{ display: 'flex' }}>
              <div className='uk-navbar-left uk-text-left'>
                <a className='uk-navbar-item uk-logo uk-visible@m' href='#'>
                  <img src={logo} alt='CA-One' />
                </a>
                {/* <a className='uk-navbar-toggle uk-hidden@m' href='#offcanvas-docs' data-uk-toggle> */}
                  {/* <span data-uk-navbar-toggle-icon></span> <span className='uk-margin-small-left'>Articles</span> */}
                {/* </a> */}
              </div>
              <div className='uk-navbar-center uk-hidden@m' style={{top:"30px", left:"50px"}}>
                <a className='uk-navbar-item uk-logo' href='#' >
                  <img src={ca} alt='CA-One' className='uk-caone-logo' />
                </a>
              </div>
              <div className='uk-navbar-right'>
                <ul className='uk-navbar-nav uk-visible@m'>
                  <div className='uk-navbar-item'>
                    <a className='uk-button uk-button-primary-outline' href='https://ca-one.com'>
                      Back to Website
                    </a>
                  </div>
                </ul>
                {/* <a className='uk-navbar-toggle uk-hidden@m' href='#offcanvas' data-uk-toggle> */}
                  {/* <span data-uk-navbar-toggle-icon></span> <span className='uk-margin-small-left'>Menu</span> */}
                {/* </a> */}
              </div>
            </div>
          </div>
        </nav>
        <div
          className='uk-section uk-position-relative uk-position-z-index'
          data-uk-scrollspy='cls: uk-animation-slide-bottom-medium; repeat: true'
        >
          <div className='uk-container'>
            <h1 className='uk-text-center uk-margin-remove-top uk-main-heading' style={{justifyContent:'left',marginLeft:'-25px'}}>CA-One Portal</h1>
          </div>
        </div>
      </header>
    
      <div className='uk-section uk-section-muted uk-section-mobile'>
        <div className='uk-container'>
          <h2 className='uk-text-left uk-margin-remove-top uk-margin-large-bottom mb-2 uk-heading-tools'>Tools</h2>
          <div className='uk-child-width-1-2@m uk-grid-match- uk-grid-small d-flex' data-uk-grid>
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://crm-india.ca-one.com' target='_blank' class='text-transform-none'>
                  <div class=' data-uk-grid'>
                    <div class='uk-width-auto uk-text-primary uk-flex uk-flex-middle '>
                      <img src={ind} alt='CA-One' width='15%' />
                    </div>
                    <div>
                      <h3 class='uk-card-title uk-margin-remove uk-text-primary'>CRM India</h3>
                      <p class='uk-text-muted uk-margin-remove'>
                        Manage leads, transactions, contacts all in one place for CA-One India Team
                      </p>
                      <button style={{ background: 'black', borderRadius: '4px' }} class='uk-button uk-button-primary uk-margin-top'>
                        Login to CRM India
                      </button>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://crm-usa.ca-one.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-width-auto uk-text-primary uk-flex uk-flex-middle'>
                      <img src={us} alt='CA-One' width='15%' />
                    </div>
                    <div>
                      <h3 class='uk-card-title uk-margin-remove uk-text-primary'>CRM US</h3>
                      <p class='uk-text-muted uk-margin-remove'>
                        Manage leads, transactions, contacts all in one place for CA-One USA Team
                      </p>
                      <button style={{ background: 'black', borderRadius: '4px' }} class='uk-button uk-button-primary uk-margin-top'>
                        Login to CRM USA
                      </button>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div class='uk-section uk-section-muted policies'>
        <div class='uk-container'>
          <h2 class='uk-text-left uk-margin-remove-top uk-margin-large-bottom mb-2 uk-heading-policies'>Policies</h2>
          <div class='uk-child-width-1-2@m uk-grid-match- uk-grid-small d-flex' data-uk-grid >
            <div >
              {/* <div class=' uk-inline uk-border-rounded'>
                <div class=' data-uk-grid'>
                  <div>
                    <h3 class='uk-card-title uk-text-primary uk-margin-small-bottom uk-usa-title'>For USA</h3>
                    <hr class='uk-divider-small uk-margin-medium-bottom' />
                  </div>
                </div>
              </div> */}
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a href={require('../policies/hand-book.pdf').default} target='_blank' class='text-transform-none'>
                    <div class='' data-uk-grid>
                       <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									    <img src={handbook} alt="CA-One" width='12%' style={{padding:'10px'}}/>
                        
                      
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Hand Book</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/first-aid-procedure.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class="" data-uk-grid>
								 <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={firstaid} alt="CA-One" width="12%" style={{padding:'10px'}}/>
								
                    
                    
                      <h3 class='uk-card-title uk-margin-remove uk-text-primary'>First Aid procedure</h3>
                    </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/hr-policies-and-practices.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                       <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={hrpolocies} alt="CA-One" width="12%" style={{padding:'10px'}}/>
								
                      
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>HR Policies and Practices</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/emergency-preparedness-and-response-plan.pdf').default}
                    
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                      <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={emergency} alt="CA-One" width="12%" style={{padding:'10px'}}/>
			
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Emergency preparedness and response plan</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/maternity-leave-policy-updated.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                       <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={pregnant} alt="CA-One" width="12%" style={{padding:'10px'}}/>
				
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Maternity Leave Policy</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              

            </div>

            <div>
            <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  
                  <a href={require('../policies/modern-slavery-policy.pdf').default} target="_blank">
                    <div class='' data-uk-grid>
                       <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={slavery} alt="CA-One" width="12%" style={{padding:'10px'}}/>
							
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Modern Slavery Policy</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/prevention-of-sexual-harrasment-policy-updated.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                      <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={harrassment} alt="CA-One" width="12%" style={{padding:'10px'}}/>
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Prevention of Sexual Harrassment at workplace</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/employer-paternity-leave-policy.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                      <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									<img src={paternity} alt="CA-One" width="12%" style={{padding:'10px'}}/>
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Employer Paternity Leave Policy</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class='uk-margin-bottom'>
                <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                  <a
                    href={require('../policies/social-compliance-policy-updated.pdf').default}
                    target='_blank'
                    class='text-transform-none'
                  >
                    <div class='' data-uk-grid>
                      <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
									      <img src={emergency} alt="CA-One" width="12%" style={{padding:'10px'}}/>
                        <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Social Compliance Policy</h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* <div>
              <div class=' uk-inline uk-border-rounded'>
                <div class='' data-uk-grid>
                  <div>
                    <h3 class='uk-card-title uk-text-primary uk-margin-small-bottom uk-usa-title'>For India</h3>
                    <hr class='uk-divider-small uk-margin-medium-bottom uk-hr' />
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a href='https://ca-one.com/wp-content/uploads/2022/05/Hand-book.pdf' target='_blank' class='text-transform-none'>
                      <div class='' data-uk-grid>
                     <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={handbook} alt="CA-One" width="12%" style={{padding:'10px'}}/>
										
                        
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Hand Book</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/First-Aid-procedure.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={firstaid} alt="CA-One" width="12%" style={{padding:'10px'}}/>
										
                        
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>First Aid procedure</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/HR-Policies-and-Practices.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={hrpolocies} alt="CA-One" width="12%" style={{padding:'10px'}}/>
							
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>HR Policies and Practices</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/Emergency-preparedness-and-response-plan.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={emergency} alt="CA-One" width="12%" style={{padding:'10px'}}/>
										
                        
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Emergency preparedness and response plan</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/MATERNITY-LEAVE-POLICY.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                         <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={pregnant} alt="CA-One" width="12%" style={{padding:'10px'}}/>
									
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'> Maternity Leave Policy</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a href='https://ca-one.com/wp-content/uploads/2022/05/Modern-Slavery-Policy.pdf'>
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={slavery} alt="CA-One" width="12%" style={{padding:'10px'}}/>
								
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Modern Slavery Policy</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/POLICY-ON-PREVENTION-OF-SEXUAL-HARASSMENT-AT-WORKPLACE.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={harrassment} alt="CA-One" width="12%" style={{padding:'10px'}}/>
									
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Prevention of Sexual Harrassment at workplace</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class='uk-margin-bottom'>
                  <div class='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                    <a
                      href='https://ca-one.com/wp-content/uploads/2022/05/Employer-Paternity-Leave-Policy.pdf'
                      target='_blank'
                      class='text-transform-none'
                    >
                      <div class='' data-uk-grid>
                        <div class="uk-width-auto uk-text-primary uk-flex uk-flex-middle uk-image-icon">
											<img src={paternity} alt="CA-One" width="12%" style={{padding:'10px'}}/>
                          <h3 class='uk-card-title uk-margin-remove uk-text-primary'>Employer-Paternity-Leave-Policy</h3>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/*  */}
      <div className='uk-section uk-section-muted'>
        <div className='uk-container'>
          <h2 className='uk-text-left uk-margin-remove-top uk-margin-large-bottom mb-2 uk-heading-products'>Products</h2>
          <div className='uk-child-width-1-2@m uk-grid-match- uk-grid-small data-uk-grid d-flex wrap'>
            {/* <div >
              <div
                className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'
              
              >
                <a href='https://eezypost.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-text-primary '>
                      <img src={eezy} alt='CA-One' />
                    </div>
                    <div>
                      <h3 class='uk-card-title uk-text-primary'>EezyPost</h3>
                      <p class='uk-text-muted ' style={{marginTop:'28px', marginBottom:'28px'}}>
                        EezyPost is a newer and quicker platform, helping people to find jobs in their dream sectors with ease. If you are
                        looking for a new job, you can sign up on EezyPost and they will do the rest
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div> */}
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://ezyhire.wpengine.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-text-primary '>
                      <img src={eezyHire2} width='120' alt='CA-One' />
                    </div>
                    <div style={{marginTop:'28px', marginBottom:'28px'}}>
                      <h3 class='uk-card-title uk-text-primary'>ezyhire.com</h3>
                      <p class='uk-text-muted ' style={{marginTop:'28px', marginBottom:'28px'}}>
                        Payroll and Hiring Offshore Resources in India, Using our Intelligent Talent Cloud, we help you hire, vet, manage,
                        and match the best offshore resources in India.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://email.nurtureclient.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-text-primary '>
                      <img src={nurtureClient} alt='CA-One' />
                    </div>
                    <div style={{marginTop:'28px', marginBottom:'28px'}}>
                      <h3 class='uk-card-title uk-text-primary'>NurtureMail</h3>
                      <p class='uk-text-muted ' style={{marginTop:'28px', marginBottom:'28px'}}>
                        Email marketing platform that drives revenue!Create beautiful, branded emails that make you look like a pro.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div
                className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'
                
              >
                <a href='https://clinic.nurtureclient.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-text-primary '>
                      <img src={nurtureClinic} width='100' alt='CA-One' />
                    </div>
                    <div style={{marginTop:'28px', marginBottom:'28px'}}>
                      <h3 class='uk-card-title uk-text-primary'>NurtureClinic</h3>
                      <p class='uk-text-muted '>
                        Our cloud-based, EHR Management System Provides solutions for small to large clinics, hospitals, or multi-specialty
                        healthcare centers.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://nurtureclient.com' target='_blank' class='text-transform-none'>
                  <div class='' data-uk-grid>
                    <div class='uk-text-primary '>
                      <img src={nurtureClient} width='100' alt='CA-One' />
                    </div>
                    <div style={{marginTop:'28px', marginBottom:'28px'}}>
                      <h3 class='uk-card-title uk-text-primary'>Nurture Client</h3>
                      <p class='uk-text-muted '>
                        We are a digital marketing agency based in silicon valley, San Francisco, striving to achieve the best digital
                        marketing results for your company.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-inline uk-border-rounded'>
                <a href='https://component-library-two.vercel.app' target='_blank' class='text-transform-none'>
                  <div class=''>
                    <div class='uk-text-primary '>
                      <img src={react} alt='CA-One' width='70' />
                    </div>
                    <div style={{marginTop:'28px', marginBottom:'28px'}}>
                      <h3 class='uk-card-title uk-text-primary'>React Component Library</h3>
                      <p class='uk-text-muted '>Get started fast with installation and theme setup instructions.</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='uk-section uk-section-muted'>
        <div className='uk-container' >
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <h2 className='uk-text-left uk-margin-remove-top uk-margin-large-bottom mb-2 card-title uk-heading-announcements'>Announcements</h2>
                  <div id='content' >
                    <ul className='timeline'>
                      <li className='event' data-date='25-11-2021'>
                        <h3>Nurture Client launch</h3>
                        <p>
                        We are a digital marketing agency based in silicon valley, San Francisco, striving to achieve the best digital
                        marketing results for your company.
                        </p>
                      </li>
                      <li className='event' data-date='25-05-2022'>
                        <h3>ezyhire.com Launch</h3>
                        <p>
                          Payroll and Hiring Offshore Resources in India, Using our Intelligent Talent Cloud, we help you hire, vet, manage,
                          and match the best offshore resources in India.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='uk-section uk-section-muted'>
        <div className='uk-container'>
          <h2 className='uk-text-left uk-margin-large-bottom'>Messages From CEO</h2>
          <div>
            <div className='uk-card uk-card uk-card-default uk-card-hover uk-card-body uk-block uk-border-rounded'>
              <a className='uk-position-cover' href='#'></a>
              <div className='uk-grid-small' data-uk-grid style={{ display: 'flex' }}>
                <div className='uk-width-auto uk-text-primary uk-flex uk-flex-middle'>
                  <img
                    className='uk-avatar uk-border-circle'
                    src='https://nurtureclient.com/wp-content/uploads/2021/10/rajul.jpg'
                    alt='CEO'
                  />
                </div>
                <div className='uk-width-expand uk-flex-middle'>
                  <h3 className='uk-card-title uk-text-primary '>Rajul</h3>
                  <p className='uk-text-muted uk-margin-remove'>
                    {' '}
                    Get started fast with installation and theme setup instructions.Get started fast with installation and theme setup
                    instructions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div id='offcanvas' data-uk-offcanvas='flip: true; overlay: true'>
        <div className='uk-offcanvas-bar'>
          <a className='uk-logo' href='#'>
            Guia
          </a>
          <button className='uk-offcanvas-close' type='button' data-uk-close></button>
          <div className='uk-margin-top uk-text-center'>
            <div data-uk-grid className='uk-child-width-auto uk-grid-small uk-flex-center'>
              <div>
                <a href='https://twitter.com/' data-uk-icon='icon: twitter' className='uk-icon-link' target='_blank'></a>
              </div>
              <div>
                <a href='https://www.facebook.com/' data-uk-icon='icon: facebook' className='uk-icon-link' target='_blank'></a>
              </div>
              <div>
                <a href='https://www.instagram.com/' data-uk-icon='icon: instagram' className='uk-icon-link' target='_blank'></a>
              </div>
              <div>
                <a href='https://vimeo.com/' data-uk-icon='icon: vimeo' className='uk-icon-link' target='_blank'></a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <footer className='uk-section uk-text-center uk-text-muted' style={{marginBottom:"0px", padding:"10px", backgroundColor:"whitesmoke"}}> 
        <div className='uk-container uk-container-small' style={{marginBottom:"0px", padding:"10px"}}>
          
          <div className='uk-margin-medium uk-text-small uk-link-muted'>
          
            Made by <a href='https://ca-one.com/'>CA-One Tech Cloud Inc.</a> Team.
          </div>
        </div>
      </footer>
      {/* <div className='uk-margin-medium'>
            <div data-uk-grid className='uk-child-width-auto uk-grid-small uk-flex-center'>
              <div className='uk-first-column'>
                <a href='https://twitter.com/' data-uk-icon='icon: twitter' className='uk-icon-link uk-icon' target='_blank'></a>
              </div>
              <div>
                <a href='https://www.facebook.com/' data-uk-icon='icon: facebook' className='uk-icon-link uk-icon' target='_blank'></a>
              </div>
              <div>
                <a href='https://www.instagram.com/' data-uk-icon='icon: instagram' className='uk-icon-link uk-icon' target='_blank'></a>
              </div>
              <div>
                <a href='https://vimeo.com/' data-uk-icon='icon: vimeo' className='uk-icon-link uk-icon' target='_blank'></a>
              </div>
            </div>
          </div> */}
    </div>
    
  );
};

export default Main;
